<template>
  <b-modal
    :visible="isModalActive"
    :title="$t('Close Menu')"
    ok-title="Accept"
    size="lg"
    @change="(val) => $emit('update:is-modal-active', val)"
  >

    <b-row class="mt-1">
      <b-col md="12">
        <b-col cols="12">
          <b-table-simple responsive>
            <b-thead head-variant="primary">
              <b-tr>
                <b-td style="width: 30%;">
                  {{ $t('System list') }}
                </b-td>
                <b-td>{{ $t('Setting') }}</b-td>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-td>{{ $t('Affiliate') }} : </b-td>
                <b-td>
                  <b-row>
                    <b-form-checkbox
                      v-model="system.closeAffiliateSystem"
                      class="custom-control-primary ml-2"
                      name="check-button"
                      switch
                      @change="switchChange('system.closeAffiliateSystem', $event)"
                    />
                    <b-card-text>
                      {{
                        system.closeAffiliateSystem != null
                          ? system.closeAffiliateSystem == true
                            ? "เปิดระบบค่านายหน้า"
                            : "ปิดระบบค่านายหน้า"
                          : "ยังไม่ได้ตั้งค่า"
                      }}
                    </b-card-text>
                  </b-row>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>{{ $t('Coupon') }} : </b-td>
                <b-td>
                  <b-row>
                    <b-form-checkbox
                      v-model="system.closeCouponSystem"
                      class="custom-control-primary ml-2"
                      name="check-button"
                      switch
                      @change="switchChange('system.closeCouponSystem', $event)"
                    />
                    <b-card-text>
                      {{
                        system.closeCouponSystem != null
                          ? system.closeCouponSystem == true
                            ? "เปิดระบบคูปอง"
                            : "ปิดระบบคูปอง"
                          : "ยังไม่ได้ตั้งค่า"
                      }}
                    </b-card-text>
                  </b-row>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>{{ $t('MLM') }} : </b-td>
                <b-td>
                  <b-row>
                    <b-form-checkbox
                      v-model="system.closeMLMSystem"
                      class="custom-control-primary ml-2"
                      name="check-button"
                      switch
                      @change="switchChange('system.closeMLMSystem', $event)"
                    />
                    <b-card-text>
                      {{
                        system.closeMLMSystem != null
                          ? system.closeMLMSystem == true
                            ? "เปิดระบบสายงาน"
                            : "ปิดระบบสายงาน"
                          : "ยังไม่ได้ตั้งค่า"
                      }}
                    </b-card-text>
                  </b-row>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>{{ $t('Point') }} : </b-td>
                <b-td>
                  <b-row>
                    <b-form-checkbox
                      v-model="system.closePointSystem"
                      class="custom-control-primary ml-2"
                      name="check-button"
                      switch
                      @change="switchChange('system.closePointSystem', $event)"
                    />
                    <b-card-text>
                      {{
                        system.closePointSystem != null
                          ? system.closePointSystem == true
                            ? "เปิดระบบคะแนน"
                            : "ปิดระบบคะแนน"
                          : "ยังไม่ได้ตั้งค่า"
                      }}
                    </b-card-text>
                  </b-row>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>{{ $t('Reservation') }} : </b-td>
                <b-td>
                  <b-row>
                    <b-form-checkbox
                      v-model="system.closeReservationSystem"
                      class="custom-control-primary ml-2"
                      name="check-button"
                      switch
                      @change="switchChange('system.closeReservationSystem', $event)"
                    />
                    <b-card-text>
                      {{
                        system.closeReservationSystem != null
                          ? system.closeReservationSystem == true
                            ? "เปิดระบบจอง"
                            : "ปิดระบบจอง"
                          : "ยังไม่ได้ตั้งค่า"
                      }}
                    </b-card-text>
                  </b-row>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>{{ $t('Warehouse') }} : </b-td>
                <b-td>
                  <b-row>
                    <b-form-checkbox
                      v-model="system.closeWarehouseSystem"
                      class="custom-control-primary ml-2"
                      name="check-button"
                      switch
                      @change="switchChange('system.closeWarehouseSystem', $event)"
                    />
                    <b-card-text>
                      {{
                        system.closeWarehouseSystem != null
                          ? system.closeWarehouseSystem == true
                            ? "เปิดระบบคลัง"
                            : "ปิดระบบคลัง"
                          : "ยังไม่ได้ตั้งค่า"
                      }}
                    </b-card-text>
                  </b-row>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-col></b-row>
    <template #modal-footer>
      <div class="w-100" />
    </template>
  </b-modal>
</template>

<script>
import {
  BTableSimple, BThead, BTh, BTbody, BTr, BTd,
  BModal,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BFormCheckbox,
  BCardText,

} from 'bootstrap-vue'

import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'

export default {
  components: {
    BTableSimple,
    BThead,
    BTh,
    BTbody,
    BTr,
    BTd,
    Cleave,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BModal,
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BFormCheckbox,
    BCardText,

  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isModalActive',
    event: 'update:is-modal-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    isModalActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => { },
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      optionCleave: {
        number: {
          numeral: true,
        },
      },
      dataId: null,
      system: {
        closePointSystem: false,
        closeMLMSystem: false,
        closeCouponSystem: false,
        closeReservationSystem: false,
        closeWarehouseSystem: false,
        closeAffiliateSystem: false,
      },
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },

  },
  watch: {
    isModalActive(val) {
      this.initValues()

      if (val) {
        if (Object.entries(this.data).length === 0) {
          this.$refs.formData.reset()
        } else {
          const {
            _id,
          } = this.data
          this.dataId = _id
          this.getCloseMenu()
        }
      }
    },
  },
  methods: {
    initValues() {
      this.dataId = null
      this.system = {
        closePointSystem: false,
        closeMLMSystem: false,
        closeCouponSystem: false,
        closeReservationSystem: false,
        closeWarehouseSystem: false,
        closeAffiliateSystem: false,
      }
    },
    getCloseMenu() {
      store
        .dispatch(`${this.storeModuleName}/getCloseMenu`, { id: this.dataId })
        .then(result => {
          console.log(result.data.data)
          const { system } = result.data.data
          if (system) {
            this.system.closeAffiliateSystem = system.closeAffiliateSystem
            this.system.closeCouponSystem = system.closeCouponSystem
            this.system.closeMLMSystem = system.closeMLMSystem
            this.system.closePointSystem = system.closePointSystem
            this.system.closeReservationSystem = system.closeReservationSystem
            this.system.closeWarehouseSystem = system.closeWarehouseSystem
          }
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
    switchChange(name, status) {
      const obj = {
        id: this.dataId,
        name,
        status,
      }
      store
        .dispatch(`${this.storeModuleName}/switchChangeCloseMenu`, obj)
        .then(result => {

        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
  },
}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  #add-new-user-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
  </style>
